/* eslint-disable @typescript-eslint/no-explicit-any */
import { Stack, Typography, Box } from "@mui/material";
import { memo, useEffect } from "react";

const Home = () => {

  useEffect(() => {
    consoleText(['Admin page', 'Some text', 'Some text'], 'text', ['tomato', 'tomato', 'tomato']);
  }, [])

  return (
    <Stack  height="100%" width='100%'
      alignItems="center" boxSizing='border-box'
      direction="row"
      className='wrapper-container'
      position='relative'
    
    >
      {/* <Stack
        sx={{
          position: "absolute",
          inset: 0,
          backgroundColor: "rgba(0, 0, 0,.4)",
          zIndex: 0,
          backdropFilter: "blur(5px)",
        }}
      ></Stack> */}
      <Stack width='100%' justifyContent="center" alignItems="center" mt={30} >
        <Typography variant="h1" fontSize={70} textTransform="uppercase" letterSpacing={1} >
          Welcome  to  RACEFI  LiveOps
        </Typography>
        <Stack direction='row' alignItems="center" boxSizing='border-box' mt={2}>
          <Typography variant="subtitle1" fontSize={30} mr={1}>
            This is
          </Typography>
          <Typography id='text' sx={{ fontSize: 30 }}></Typography>
          <Typography className='console-underscore' id='console' sx={{ fontSize: 25, fontWeight: 'bold' }}>&#95;
          </Typography>
        </Stack>
      </Stack>
    </Stack >
  );
};

export default memo(Home);

function consoleText(words, id, colors) {
  if (colors === undefined) colors = ['#fff'];
  let visible = true;
  const con = document && document?.getElementById('console') as any;
  let letterCount = 1;
  let x = 1;
  let waiting = false;
  const target = document.getElementById(id) as any
  target?.setAttribute('style', 'color:' + colors[0])
  window.setInterval(function () {

    if (letterCount === 0 && waiting === false) {
      waiting = true;
      target.innerHTML = words[0].substring(0, letterCount)
      window.setTimeout(function () {
        const usedColor = colors.shift();
        colors.push(usedColor);
        const usedWord = words.shift();
        words.push(usedWord);
        x = 1;
        target.setAttribute('style', 'color:' + colors[0])
        letterCount += x;
        waiting = false;
      }, 1000)
    } else if (letterCount === words[0].length + 1 && waiting === false) {
      waiting = true;
      window.setTimeout(function () {
        x = -1;
        letterCount += x;
        waiting = false;
      }, 1000)
    } else if (waiting === false) {
      target.innerHTML = words[0].substring(0, letterCount)
      letterCount += x;
    }
  }, 120)
  window.setInterval(function () {
    if (visible === true) {
      con.className = 'console-underscore hidden'
      visible = false;

    } else {
      con.className = 'console-underscore'

      visible = true;
    }
  }, 300)
}